<template>
  <div style="height: 65vh; position: relative">
    <div class="column justify-center">
      <div>
        <div class="row justify-center align-center pt-3">
          <div style="width: 32%" class="pb-5">
            <img
              src="../assets/images/LOGO_SONPHAT-New.png"
              style="height: ; width: 100%"
            />
          </div>
        </div>
      </div>
      <div style="z-index: 99999">
        <div class="row justify-center pt-3">
          <div class="xs4 pr-3">
            <img
              src="../assets/images/404.png"
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="xs4 pl-3 align-self-center">
            <div class="row justify-center pb-5">
              <div style="width: 70%; text-align: center">
                <div
                  v-if="error.statusCode != 401 && error.statusCode != 403"
                  class="font-24 font-regurlar pb-3"
                >
                  Đã xảy ra lỗi!
                </div>
                <div
                  v-if="error.statusCode === 401"
                  style="word-wrap: break-word"
                  class="font-24 font-regurlar pb-3"
                >
                  Vui lòng đăng nhập lại!
                </div>
                <div
                  v-if="error.statusCode === 403"
                  style="word-wrap: break-word"
                  class="font-24 font-regurlar pb-3"
                >
                  Không đủ quyền truy cập!
                </div>
                <div
                  v-if="error.statusCode === 404"
                  style="word-wrap: break-word"
                >
                  Tải lại trang<br />
                  Kiểm tra lại đường dẫn URL<br />
                  Sửa lại địa chỉ URL<br />
                  Xem thêm tại tài liệu hướng dẫn<br />
                </div>
                <div
                  v-if="error.statusCode === 500"
                  style="word-wrap: break-word"
                >
                  Có lỗi hệ thống
                  <p v-if="error.isAxiosError">
                    Không thể kết nối đến máy chủ API.
                  </p>
                </div>
              </div>
            </div>
            <div class="row justify-center align-center pt-3">
              <div class="pr-3">
                <DxButton
                  v-if="error.statusCode === 401"
                  id=""
                  text="Đăng nhập"
                  type="default"
                  styling-mode="contained"
                  icon="mdi mdi-home"
                  hint="Đăng nhập"
                  @click="$router.push('/Dang-Nhap')"
                />
                <DxButton
                  v-if="error.statusCode != 401"
                  id=""
                  text="Quay về Trang chủ"
                  type="default"
                  styling-mode="contained"
                  icon="mdi mdi-home"
                  hint="Quay về Trang chủ"
                  @click="$router.push('/')"
                />
              </div>
              <!-- <div class="pl-3">
                                <nuxt-link to="/">
                                    <DxButton
                                        id=""
                                        text="Tài liệu hướng dẫn"
                                        type="default"
                                        styling-mode="outlined"
                                        icon="mdi mdi-file-document-multiple"
                                        hint="Trang chủ"
                                /></nuxt-link>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; position: fixed; bottom: 0">
      <img
        src="../assets/images/bg-404.png"
        style="height: 100%; width: 100%; opacity: 0.1"
      />
    </div>
    <!-- <h1 v-if="error.statusCode === 404">Page not found</h1>
    <h1 v-else>An error occurred</h1>
    <NuxtLink to="/">Home page</NuxtLink> -->
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
export default {
  components: {
    DxButton,
  },
  data() {
    return {
      error: {
        statusCode: 401,
      },
    };
  },
  // layout: "error", // you can set a custom layout for the error page
  mounted() {
    let error = this.$route.query;
    this.error.statusCode = parseInt(error.statusCode) || 401;
    console.log(arguments, this.error);
  },
};
</script>